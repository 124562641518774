import { atom, useSetRecoilState } from "recoil";

export const promptOpenState = atom<{ open: boolean; title?: string }>({
  key: "passwordPromptState",
  default: {
    open: false,
    title: undefined,
  },
});

export type Field = {
  placeholder?: string;
  label?: string;
  // pourrai changer
  type?: "text" | "password";
};

export type Fields<T> = { [P in keyof T]: Field };

export const promptFieldOptions = atom<Fields<any>>({
  key: "promptFieldOptions",
  default: {},
});

export const promtFuncState = atom({
  key: "passwordPromtFuncState",
  default: {
    resolve: (value: any) => {},
    reject: (value: any) => {},
  },
});

export function usePrompt<T>(fields: Fields<T>, title?: string) {
  const setOpen = useSetRecoilState(promptOpenState);
  const setFunc = useSetRecoilState(promtFuncState);
  const setFields = useSetRecoilState(promptFieldOptions);
  return () => {
    setOpen({ open: true, title });
    setFields(fields);
    return new Promise<{ [P in keyof Fields<T>]: string }>(
      (resolve, reject) => {
        // sinon resolve est confondue avec un setter et est appelée directement avec l'ancien state
        setFunc(() => ({ resolve, reject }));
      }
    );
  };
}

export const usePassword = () =>
  usePrompt({ password: { type: "password" } }, "Password");
