import { get, patch, post, put } from "../../api/api"
import { Page } from "../../utils/utils";
import { ExternalProvider, ExternalResource } from "./type";

export const getAllExternalProviders = () => {
    return get<ExternalProvider[]>("/external_resources/all");
}

export const indexExternalProvider = (id: number) => {
    return post<{ error: boolean }>(`/external_resources/index/${id}`, {});
}

export const getResourcesByProvider = (id: number) => {
    return get<ExternalResource[]>(`/external_resources/${id}`);
}

export const getRessourcesPaged = (providerId:number, page: number) => {
    return get<Page<ExternalResource>>(`/external_resources/${providerId}/table?page=${page}`);
}

export const toggleActivated = (providerId:number, resourceId: number, activated: boolean) => {
    return patch<void>(`/external_resources/${providerId}/${resourceId}/activated?activated=${activated}`)
}

