import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./assets/fonts/bree.tff";
import "./assets/fonts/din.tff";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const trackingId = "G-1CYM0BZQ0F";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
