import React from "react";
import { Folder } from "../../../store/type";
import { UserState } from "../../../store/user/user";
import { useTitle } from "../../../utils/utils";
import Page from "../Page/Page";
import PageCard from "./pageCard";

type Props = {
  folder: Folder;
  user: UserState;
  shown: boolean;
};

const removeUnpublishedPages = (folder: Folder) => {
  folder.pages = folder.pages.filter(p => p.published);
  folder.sub_folders.forEach(removeUnpublishedPages);
}

function SubFolder(props: Props) {
  const { folder, user, shown } = props;
  useTitle(shown ? folder.name : undefined);
  if (!user?.admin) {
    removeUnpublishedPages(folder);
  }
  return folder.pages.length === 1 && folder.pages[0].auto_open ? (
    <Page id={folder.pages[0].id} embeded />
  ) : (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {folder.pages.map((p) => (
        <PageCard key={p.id} user={user} page={p} />
      ))}
    </div>
  )
}

export default SubFolder;
