import { Avatar, Hidden, IconButton, Link, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import React, { memo, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { kiwiAddress } from "../../../App";
import { userState } from "../../../store/user/user";
import DarkModeToggle from "../DarkmodeToggle/Toggle";


const useStyles = makeStyles((theme) => ({
    div: {
        // marginLeft: "auto",
    },
    typo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    span: {
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
}));

const ProfileButton = memo(() => {
    const [user, setUser] = useRecoilState(userState);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorEl(event.currentTarget);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);
    const classes = useStyles();
    const {keycloak} = useKeycloak();
    const [profileAttributes, setProfile] = useState<any>();
    // console.log(profileAttributes);
    useEffect(()=>{
        // @ts-ignore
        keycloak.loadUserProfile().then(r => setProfile(r.attributes));
    }, [keycloak]);

    const handleLogout = () => {
        setUser(undefined);
        keycloak.logout();
    };

    const asUser = () => {
        setUser(u => ({ ...u!, admin: false }));
    };

    return (
        <div className={classes.div}>
            <Typography variant="h6" className={classes.typo}>
                <Hidden xsDown implementation="css">
                    {user?.fullName || "?"}
                </Hidden>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <Avatar src={"https://documents.junior-entreprises.com/kiwi-public/" +(profileAttributes?.profile_picture_url || [''])[0]} />
                </IconButton>
            </Typography>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem>
                    <DarkModeToggle />
                </MenuItem>
                {user?.admin && [
                    <MenuItem onClick={asUser}>
                        Vue utilisateur
                    </MenuItem>,
                    <MenuItem>
                        <RouterLink
                            style={{ textDecoration: "none" }}
                            to="/howTo"
                            color="primary"
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <span className={classes.span}>
                                Tips and Tricks
                            </span>
                        </RouterLink>
                    </MenuItem>
                ]}
                <MenuItem>
                    <Link href={kiwiAddress}>
                        Retour vers Kiwi
                    </Link>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleLogout();
                    }}
                >
                    Déconnexion
                </MenuItem>
            </Menu>
        </div>
    );
});

export default ProfileButton;
