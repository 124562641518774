import { Card, CardContent, CardHeader, Checkbox, ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getRessourcesPaged, toggleActivated } from '../../../../store/external_resources/action';
import { ExternalResource } from '../../../../store/external_resources/type';
import PagedItems from '../../../common/Paging/PagedItems';

type ResourcesCardProps = {
    resources: ExternalResource;
}

function ResourceCard(resource: ExternalResource) {

    const [checked, setChecked] = useState(!resource.deactivated);

    const handleChange = (e: any) => {
        toggleActivated(0, resource.id, e.target.checked);
        setChecked(e.target.checked);
    }

    return (
        <ListItem>
            <ListItemText primary={resource.name} />
            <Checkbox checked={checked} onChange={handleChange} />
        </ListItem>
    );
}

export default function OneProvider() {
    const { id } = useParams<{ id: string }>();

    return (
        <Card>
            <CardHeader title={`Provider #${id}`} />
            <CardContent>
                {/* afficher les settings ici */}
                <div>
                    <Typography>
                        Ressources :
                    </Typography>
                    <PagedItems
                        getter={(page: number) => getRessourcesPaged(+id, page)}
                        component={ResourceCard}
                    />
                </div>
            </CardContent>
        </Card>
    )
}