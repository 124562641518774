import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { Link } from "react-router-dom";
import { LightPage } from '../../../store/type';


type Props = {
    selected: boolean;
    page: LightPage;
    handleClose: () => void;
}

function PageItem(props: Props) {
    const { handleClose, page, selected } = props;

    return (
        <ListItem
            button
            onClick={handleClose}
            selected={selected}
            component={props => <Link to={`/page/${page.id}`} {...props} />}
        >
            {/* ajouter le matching en gras */}
            <ListItemText primary={page.name} secondary={page.description} />
        </ListItem>
    )
}

export default React.memo(PageItem);