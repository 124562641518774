import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,


  Link, makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import React, { memo } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Page } from "../../../store/type";
import { UserState } from "../../../store/user/user";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: "20.5em",
    width: "32.36em",
    maxWidth: "90vw",
    margin: '0.5rem',
  },
  media: {
    height: "18em",
  },
  actionArea: {
    height: '100%',
  },
  title: {
    fontFamily: "Bree",
    marginTop: 'auto',
    position: "absolute",
    bottom: "3.3em",
    left: "0.6em",
    fontSize: "1.2em",
    color: theme.palette.secondary.contrastText,
  },
  subtitle: {
    fontFamily: "Bree",
    fontSize: "1.5em",
    marginBottom: '0.3em',
    marginTop: '0',
  },
}));

type Props = {
  page: Page;
  user: UserState;
};

const defaultNoImage =
  "https://sisterhoodofstyle.com/wp-content/uploads/2018/02/no-image-1.jpg";

function PageCard(props: Props) {
  const { page } = props;
  const classes = useStyles();

  return (
    <Link underline='none' component={RouterLink} to={`/page/${page.id}`}>
      <Card className={classes.root}>
        <CardActionArea className={classes.actionArea}>
          <CardMedia
            className={classes.media}
            image={page.img_url || defaultNoImage}
          >
            <Typography variant="h5" component="h2" className={classes.title}>
              {page.name}
            </Typography>
          </CardMedia>
          <CardContent style={{ paddingBottom: 0, paddingTop: '0.5em' }}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="h3"
              className={classes.subtitle}
            >
              {page.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

export default memo(PageCard, (prev, next) => prev.page.id === next.page.id);
