import { fade, List, ListItem, makeStyles, Popover, Theme } from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { get } from "../../../api/api";
import { ExternalResource } from "../../../store/external_resources/type";
import { LightPage } from "../../../store/type";
import { removeExt } from "../../../utils/utils";
import ExternalResourceItem from './externalResourceItem';
import PageItem from './pageItem';

const useStyles = makeStyles((theme: Theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     width: '20ch',
        // },
    },
    list: {
        // width: '20em'
        // width:'100%',
    },
    popover: {
        maxHeight: '90vh',
        width: '100%',
        maxWidth: '30em',
    },
    popoverRoot: {
        maxWidth: '90vw',
        width: '50em'
    }
}));

type SearchResult = {
    pages: LightPage[];
    resources: ExternalResource[]
}

const emptyResult = { pages: [], resources: [], total: 0 };

export default function SearchField() {
    const [result, setResult] = useState<SearchResult & { total: number }>(emptyResult);
    const [opened, setOpened] = useState({ open: false, justClosed: false });
    const { open: openedOpen } = opened;
    const [value, setValue] = useState('');
    const classes = useStyles();
    const anchor = useRef<HTMLElement>();
    const inputRef = useRef<HTMLElement>();
    const [selectedResult, setSelectedResult] = useState(-1);
    const history = useHistory();

    const handleClose = useCallback(() => {
        setTimeout(() => {
            inputRef.current!.blur();
            setSelectedResult(0);
        });
        setOpened({ open: false, justClosed: true });
    }, []);

    const handleKey = useCallback((e: KeyboardEvent) => {
        if (openedOpen) {
            if (e.key === "ArrowDown") {
                setSelectedResult(old => {
                    if (old < result.total - 1) {
                        return old + 1;
                    }
                    return old;
                });
                // set selected + 1
                // handle max results
            } else if (e.key === "ArrowUp") {
                setSelectedResult(old => old > 0 ? old - 1 : 0);
            } else if (e.key === "Enter") {
                // go to result
                let url = "";
                const e = [...result.pages, ...result.resources][selectedResult];
                if (selectedResult > result.pages.length) {
                    // @ts-ignore
                    url = `/view?name=${removeExt(e)}&url=${e.url}`;
                } else {
                    url = `/page/${e.id}`;
                }
                handleClose();
                history.push(url);
            } else if (e.key === "Escape") {
                handleClose();
            }
        }
    }, [openedOpen, result.total, selectedResult, result.pages, result.resources, history, handleClose]);

    useEffect(() => {
        window.addEventListener('keydown', handleKey);
        return () => {
            window.removeEventListener('keydown', handleKey);
        }
    }, [handleKey]);

    const handleChange = useCallback(async (v: string, opened: boolean) => {
        setValue(v);
        if (v !== '') {
            try {
                v = v.replaceAll(' ', ' & ');
                const res = await get<SearchResult>(`/page/search?q=${encodeURIComponent(v)}`);
                setResult({ ...res, total: res.pages.length + res.resources.length });
                if (!opened) {
                    setOpened({ open: true, justClosed: false });
                    setSelectedResult(0);
                }
            } catch (e) {

            }
        }
    }, []);




    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                // error={value.includes(' ')}
                autoFocus
                onFocus={() => {
                    setOpened(o => ({ open: value !== '' && !o.justClosed, justClosed: false }));
                }}
                onClick={() => {
                    setOpened(o => ({ open: value !== '' && !o.justClosed, justClosed: false }));
                }}
                inputRef={inputRef}
                ref={anchor}
                value={value}
                // type="search"
                onChange={e => handleChange(e.target.value, openedOpen)}
                placeholder="Chercher..."
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            />

            <Popover
                // the good stuff
                disableAutoFocus
                disableEnforceFocus
                disablePortal
                anchorEl={anchor.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={value !== '' && opened.open}
                onClose={handleClose}
                className={classes.popover}
                classes={{
                    root: classes.popoverRoot,
                }}
            // PaperProps={{ style: { width: '100%' } }}
            >

                <List className={classes.list}>
                    {result.pages.map((e, i) => (
                        <PageItem
                            handleClose={handleClose}
                            page={e}
                            selected={selectedResult === i}
                        />
                    ))}
                    {result.resources.map((e, i) => (
                        <ExternalResourceItem
                            handleClose={handleClose}
                            resource={e}
                            selected={selectedResult === i + result.pages.length}
                        />
                    ))}
                </List>
                {result.total > 0 ? (
                    <div style={{ textAlign: 'center', width: '20em' }}>
                        <ListItem>
                            {result.total} Résultat{result.total > 1 ? 's' : ''}
                        </ListItem>
                    </div>
                ) : (
                    <ListItem style={{ width: '20em' }}>
                        Pas de résultats
                    </ListItem>
                )}

            </Popover>

        </div >
    );
}