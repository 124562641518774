import { CircularProgress, CssBaseline, makeStyles, Theme } from "@material-ui/core";
import React, { ReactNode, Suspense, useState } from "react";
import AppBar from "./AppBar";
import Drawer from "./Drawer";
import { appBarHeight } from "./menuContentStyle";


export const useStyles = makeStyles((theme: Theme) => ({
	root: () => ({
		flexGrow: 1,
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100vh",
		zIndex: 1,
		// justifyContent: props.open ? undefined : "center",
		// overflow: "hidden",
	}),
	toolbar: {
		height: appBarHeight
	},
	shiftTextRight: {
		maxWidth: "100%",
		width: "100%", //a ne pas enlever, permet d'avoir un content responsive peut importe la longueur du screen (je sais pas pourquoi)
		flexGrow: 1,
		// padding: theme.spacing(3),
		transition: theme.transitions.create('padding', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// paddingLeft: drawerWidthOpen,
		// marginLeft: "auto",
		// marginRight: "auto",
		willChange: "transform",
	},
	shiftTextLeft: {
		maxWidth: "100%",
		width: "100%", //a ne pas enlever, permet d'avoir un content responsive peut importe la longueur du screen (je sais pas pourquoi)
		flexGrow: 1,
		// padding: theme.spacing(3),
		transition: theme.transitions.create('padding', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// marginRight: "auto",
		// marginLeft: "auto",
		willChange: "transform",
	},
	div: {
		display: "flex",
		alignItems: "center",
		minHeight: `calc(100% - ${appBarHeight}px)`,
	},
	progress: {
		marginLeft: "auto",
		marginRight: "auto",
	},
	content: {
		backgroundColor: theme.palette.background.default,
		flexGrow: 1,
		minHeight: "100%",
		// width: 0, //a ne pas enlever, permet d'avoir un content responsive peut importe la longueur du screen (je sais pas pourquoi)
	},
}));


const Layout = (props: { children: ReactNode }) => {
	const [drawerOpen, setDrawerOpen] = useState(true);
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar drawerOpen={drawerOpen} setDrawer={setDrawerOpen} />
			<Drawer open={drawerOpen} setDrawer={setDrawerOpen} />
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Suspense
					fallback={
						<div className={classes.div}>
							<CircularProgress className={classes.progress} />
						</div>
					}>
					{props.children}
				</Suspense>
			</main>
		</div>
	);
};

export default Layout;