import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Link } from "react-router-dom";
import { ExternalResource } from '../../../store/external_resources/type';
import { removeExt } from '../../../utils/utils';

type Props = {
    selected: boolean;
    resource: ExternalResource;
    handleClose: () => void;
}

const providers: { [provider_type: string]: string } = {
    gdrive: "Document",
    livestorm: "Livestorm",
}


const getSecondary = (e: ExternalResource) => {
    return providers[e.provider];
}

function ExternalResourceItem(props: Props) {
    const { selected, resource, handleClose } = props;
    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        if (selected) {
            ref.current?.scrollIntoView();
        }
    }, [selected, ref]);

    return (
        <ListItem
            
            button
            // autoFocus={i === 0}
            onClick={handleClose}
            // selected={selectedResult === itemNumber + pagesLength}
            selected={selected}
            component={props => <Link to={`/view?name=${removeExt(resource)}&url=${resource.url}`} {...props} />}
        >
            {/* ajouter le matching en gras */}
            <ListItemText ref={ref} primary={removeExt(resource)} secondary={getSecondary(resource)} />
        </ListItem>
    )
}

export default React.memo(ExternalResourceItem);