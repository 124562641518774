import keycloack from '../keycloak';

export const adr = process.env.REACT_APP_API_URL;

export class HTTPRequestError extends Error {
  code: number;
  constructor(code: number, message: string) {
    super(message);
    this.code = code;
    this.name = "HTTP request error";
  }
}
export async function api<T = void>(
  addr: string,
  method: string,
  body?: any
): Promise<T> {
  console.log('api call', keycloack);
  const headers = new Headers();
  console.log('token exprired', keycloack.isTokenExpired())
  if (keycloack.isTokenExpired()) {
    console.log('token exprired')
    await keycloack.updateToken(0);
  }

  headers.append("Authorization", `Bearer ${keycloack.token}`);

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");

  const res = await fetch(adr + addr, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });

  if (!res.ok) {
    try {
      throw new HTTPRequestError(res.status, await res.json());
    } catch (e) {
      if (res.status === 401) {
        await refreshToken();
        return api<T>(addr, method, body);
      }
      throw new HTTPRequestError(res.status, "Unknown error");
    }
  } else {
    if (res.status === 204) {
      return (void 0 as unknown) as T;
    } else {
      const js = await res.json();
      return js as T;
    }
  }
}

export function get<T>(addr: string) {
  return api<T>(addr, "GET");
}

export function post<T>(addr: string, body: any) {
  return api<T>(addr, "POST", body);
}

export function put<T>(addr: string, body?: any) {
  return api<T>(addr, "PUT", body);
}

export function patch<T>(addr: string, body?: any) {
  return api<T>(addr, "PATCH", body);
}

export function del<T = void>(addr: string, body?: any) {
  return api<T>(addr, "DELETE", body);
}
function refreshToken() {
  throw new Error('Function not implemented.');
}

