import Keycloak from 'keycloak-js';

// https://auth.finito.junior-entreprises.com/realms/CNJE

const keycloak = new Keycloak({
  realm: "JEI",
  url: "https://identity.junior-entreprises.com",
  // "ssl-required": "external",
  // TODO: change it to correct app later
  clientId: "kiwi-forma",
  // "public-client": true,
});

keycloak.onTokenExpired = () => {
  if (keycloak.authenticated){
    keycloak.updateToken(100);  
  }
}

// keycloak.flow = 'implicit';


export default keycloak;