import * as React from "react";
import BaseDarkModeToggle from "react-dark-mode-toggle";
import { useRecoilState } from "recoil";
import {
  ThemeSettings,
  themeState,
  THEME_TOKEN,
} from "../../../store/theme/atom";

export function useDarkmode(): [ThemeSettings, (value: boolean) => void] {
  const [darkmode, setDarkmode] = useRecoilState(themeState);

  React.useEffect(() => {
    const textSettings = localStorage.getItem(THEME_TOKEN);
    if (textSettings) {
      const settings: ThemeSettings = JSON.parse(textSettings);
      setDarkmode({ dark: settings.dark });
    }
  }, [setDarkmode]);

  const _setDarkmode = (value: boolean) => {
    setDarkmode({ dark: value });
    localStorage.setItem(THEME_TOKEN, JSON.stringify({ dark: value }));
  };
  return [darkmode, _setDarkmode];
}

const DarkModeToggle = () => {
  const [theme, setDarkmode] = useDarkmode();
  return <BaseDarkModeToggle onChange={setDarkmode} checked={theme.dark} />;
};

export default DarkModeToggle;
