import {
  Drawer as MaterialUIDrawer,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ReactSetState } from "../../../utils/utils";
import { useDarkmode } from "../DarkmodeToggle/Toggle";
import MenuContent from "./MenuContent";
import { appBarHeight, drawerWidthClose, drawerWidthOpen } from "./menuContentStyle";

const useStyles = makeStyles((theme: Theme) => ({
  drawerOpen: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthOpen,
      flexShrink: 0,
    }
  },
  drawerClose: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthClose
    }
  },
  drawerPaper: ({ darkMode }: { darkMode: boolean }) => ({
    marginTop: appBarHeight,
    // background: darkMode ?
    //   `linear-gradient(0deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 99%, ${theme.palette.background.paper} 100%);`
    //   : theme.palette.primary.main,
    backgroundColor: '#003C50',
    // willChange: "transform",
    // width: drawerWidthOpen,
    overflowX: "hidden",
  }),
  drawerPaperOpen: {
    width: drawerWidthOpen,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    overflow: 'hidden',
  },
  drawerPaperClose: {
    width: drawerWidthClose,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    overflow: 'hidden',
  },
  // drawer: {
  //   [theme.breakpoints.up("md")]: {
  //     width: 0,
  //     flexShrink: 0,
  //   },
  // },
}));

type Props = {
  open: boolean;
  setDrawer: ReactSetState<boolean>;
};

const Drawer = (props: Props) => {
  const { open: drawerOpen, setDrawer } = props;
  const [darkMode] = useDarkmode();

  const classes = useStyles({ darkMode: darkMode.dark });


  const handleDrawer = () => {
    setDrawer(false);
  };

  return (
    <nav className={clsx(classes.drawerOpen, {
      [classes.drawerClose]: !drawerOpen,
    })}>
      <Hidden mdUp implementation="js">
        <MaterialUIDrawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawer}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerPaperOpen]: drawerOpen,
              [classes.drawerPaperClose]: !drawerOpen,
            }),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <MenuContent onClose={handleDrawer} />
        </MaterialUIDrawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <MaterialUIDrawer
          variant="permanent"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawer}
          className={clsx(classes.drawerPaper, {
            [classes.drawerPaperOpen]: drawerOpen,
            [classes.drawerPaperClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerPaperOpen]: drawerOpen,
              [classes.drawerPaperClose]: !drawerOpen,
            })
          }}
        >
          <MenuContent />
        </MaterialUIDrawer>
      </Hidden>



    </nav>
  );
};

export default Drawer;
