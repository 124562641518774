import { Icon, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import SettingsIcon from '@material-ui/icons/Settings';
import React from "react";
import { Link } from "react-router-dom";
import { useRoutes } from "../../../store/routes/hook";
import { useUser } from "../../../store/user/user";
import { useStyles } from "./menuContentStyle";

type Props = {
  onClose?: () => void;
};

const useLinkStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.contrastText,
    overflow: 'hidden',
    height: '1.5rem'
  },
}));

function MenuContent(props: Props) {
  const { onClose } = props;
  const linkClasses = useLinkStyles();
  const classes = useStyles();
  const { routes } = useRoutes();
  const user = useUser();


  return (
    <div className={classes.div}>
      <List>
        {routes?.sub_folders.map((r) => (
          <Link
            key={r.name}
            onClick={onClose}
            style={{ textDecoration: "none" }}
            to={`/folder/${r.name}`}
          >
            <ListItem button>
              <ListItemIcon>
                <Icon className={classes.secondaryColor}>{r.icon}</Icon>
              </ListItemIcon>
              <ListItemText
                primary={r.name}
                color="primary"
                className={linkClasses.listItem}
              />
            </ListItem>
          </Link>
        ))}
        {user?.admin &&
          <Link
            onClick={onClose}
            style={{ textDecoration: "none" }}
            to="/admin"
          >
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon className={classes.secondaryColor} />
              </ListItemIcon>
              <ListItemText
                primary="Administration"
                color="primary"
                className={linkClasses.listItem}
              />
            </ListItem>
          </Link>
        }
      </List>
    </div>
  );
}

export default MenuContent;
