import { atom } from "recoil";

export type ThemeSettings = {
  dark: boolean;
};

export const THEME_TOKEN = "theme_settings";

export const themeState = atom<ThemeSettings>({
  key: "theme",
  default: {
    dark: false,
  },
});
