import { Card, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useTitle } from '../../../utils/utils';

const useStyles = makeStyles(() => ({
    card: {
        width: '60rem',
        maxWidth: '90%',
        height: '90vh',
        textAlign: 'center',
        // justifyContent: 'center',
        margin: 'auto',
    },
    iframe: {
        width: '100%',
        height: '100%',
        // margin: 'auto',
        // display: 'block'
    },
    div: {
        width: '100%',
        height: '90vh',
        paddingTop: '1rem'
    },
}));

export default function ViewEmbed() {
    const [query] = useQueryParams({
        url: StringParam,
        name: StringParam,
    });
    const { url, name } = query;
    useTitle(name || 'Kiwi Formation');
    const classes = useStyles();

    return (
        <div className={classes.div}>
            <Card className={classes.card}>
                {url && name ? (
                    <>
                        {/* <Typography variant="h2" component="h1">
                            {name}
                        </Typography> */}
                        <iframe title={name} className={classes.iframe} src={url} >
                            Chargement en cours
                        </iframe>
                    </>
                )
                    :
                    <Typography>
                        URL invalide
                    </Typography>
                }
            </Card>
        </div>
    )
}