import { useEffect } from "react";
import { ExternalResource } from "../store/external_resources/type";

// const defaultTitle = "Kiwi Formation";

export function makePersistor<T>(name: string) {
	function get(): T | null {
		const d = localStorage.getItem(name);
		if (d) {
			return JSON.parse(d);
		} else {
			return null;
		}
	}
	const set = (data: T) => {
		localStorage.setItem(name, JSON.stringify(data));
	}
	return { get, set }
}

export function useTitle(title?: string) {
  useEffect(() => {
    if (title){
      document.title = title;
    }
    // return () => {
    //   document.title = defaultTitle;
    // };
  }, [title]);
}

export type ReactSetState<T> = React.Dispatch<React.SetStateAction<T>>;

export function useUpload(setLoading: ReactSetState<boolean>, after: Function) {
  // fix le loading qui ne passe pas à false
  const upload = (url: string, file: File) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, false);

    xhr.upload.addEventListener("error", (event) => {
      throw new Error("Une erreur est survenue lors de l'upload du fichier");
    });

    xhr.upload.addEventListener("progress", (event) => {
      // console.log(event);
      // TODO: bizarre
      if (event.lengthComputable) {
        const { loaded, total } = event;
        // TODO: fix, loading pas bien mis à false
        if (loaded / total === 1) {
          setLoading(false);
          after();
        }
      }
    });
    xhr.onloadend = (e) => {
      setLoading(false);
      after();
    };
    xhr.send(file);
  };

  return { upload };
}

export type Page<T> = {
  data: T[];
  count: number;
};


const makeNameProvider = (prefix: string) => {
  let id = 0;
  return () => {
    return `${prefix}${id++}`;
  }
}

export const removeExt = (r: ExternalResource)=>{
  if (r.provider === "gdrive"){
    return r.name.split('.').slice(0, -1).join('.');
  }
  return r.name;
}


const defaultNameProvider = makeNameProvider('forma');

export function makeDataHandler(nameProvider: () => string): [() => string | null, (d: string) => void] {
  const name = nameProvider();
  const store = (data: string) => {
    localStorage.setItem(name, data);
  }

  const get = () => {
    return localStorage.getItem(name);
  }
  return [get, store];
}

export const [getPhoto, storePhoto] = makeDataHandler(defaultNameProvider);

