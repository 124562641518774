import {
  AppBar as MaterialUIAppBar,
  Container, Grow, IconButton,
  makeStyles, Theme,
  Toolbar
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from "@material-ui/icons/Menu";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import { useRoutes } from "../../../store/routes/hook";
// import { Folder } from "../../../store/type";
import { ReactSetState } from "../../../utils/utils";
import Hotjar from "../Misc/Hotjar";
import SearchField from "../SearchField/SearchField";
import ProfileButton from "./profileButton";


// const tabFromPath = (pagesTo: Folder[]) => {
//   const a = pagesTo
//     .map((p) => p.name)
//     .indexOf(decodeURIComponent(document.location.pathname.split("/")[2]));
//   return a;
// };

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    // same as appBar height
    height: "3em",
    // marginRight: "4em",
    marginTop: "1em",
  },
  appBar: {
    height: "4em",
    paddingTop: "",
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
}));

type Props = {
  setDrawer: ReactSetState<boolean>;
  drawerOpen: boolean;
};

function AppBar(props: Props) {
  const { ensureRoutes } = useRoutes();
  const { setDrawer, drawerOpen } = props;
  const classes = useStyles();

  useEffect(() => {
    ensureRoutes();    
  }, [ensureRoutes]);

  return (
    <>
      <Hotjar />
      <MaterialUIAppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <div>
            {!drawerOpen &&
              <Grow in={!drawerOpen}>

                <IconButton
                  className={classes.icon}
                  onClick={() => setDrawer(o => !o)}
                >
                  <MenuIcon />
                </IconButton>

              </Grow>
            }
            {drawerOpen &&
              <Grow in={drawerOpen}>
                <IconButton
                  className={classes.icon}
                  onClick={() => setDrawer(o => !o)}
                >
                  <CloseIcon />
                </IconButton>
              </Grow>
            }
          </div>
          <a href="/">
            <img className={classes.logo} src={Logo} alt="logo" />
          </a>
          {/* <Hidden xsDown implementation="css">
            <Tabs
              variant="scrollable"
              value={tab}
              onChange={handleChange}
              TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary.contrastText } }}
              style={{ width: '63vw' }}
            >
              {user && routes &&
                routes.sub_folders.map(r => (
                  <Tab
                    key={r.name}
                    label={r.name}
                    to={`/folder/${r.name}`}
                    component={Link}
                  />
                ))}
              {user?.admin && (
                <Tab label="administration" component={Link} to="/admin" />
              )}
            </Tabs>
          </Hidden> */}
          {/* <div style={{ marginLeft: 'auto', display: 'flex' }}> */}
          {/* <Container style={{width:'20rm'}}> */}
          {/* <div style={{ marginRight: '1em', paddingTop: "1em" }}> */}
          <Container style={{ maxWidth: '30em' }}>
            <SearchField />
          </Container>
          <ProfileButton />
          {/* </div> */}

          {/* </div> */}
        </Toolbar>
      </MaterialUIAppBar>
    </>
  );
}

export default AppBar;
