import {
  atom, useRecoilValue, useSetRecoilState
} from "recoil";

export type UserState = {
  username: string;
  admin: boolean;
  fullName: string;
  photo_url?: string;
};

export const userState = atom<UserState | undefined>({
  key: "user",
  default: undefined,
});

export function useUser() {
  const user = useRecoilValue(userState);
  return user;
}


export function useStoreUser() {
  const store = useSetRecoilState(userState);
  return store;
}