import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

const BaseTextField = (props: TextFieldProps) => (
  <TextField
    {...props}
    inputProps={{ style: { textAlign: "center", ...props.style } }}
    variant="outlined"
    margin="dense"
  />
);

export default BaseTextField;
