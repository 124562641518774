import { del, get, patch, post } from "../../api/api";
import { Folder, Page } from "../type";

export const getPage = (pageId: number) => get<Page>(`/page/${pageId}`);

export const createPage = (folderId: number, pageName: string) => post<Page>("/page/", { name: pageName, folder_id: folderId });

export const deletePage = (pageId: number) => del<void>(`/page/${pageId}`);

export const patchPage = (pageId: number, page: Partial<Page>) => patch(`/page/${pageId}`, page);;

export const getFolder = (folderName: string) => get<Folder>(`/folder/${folderName}`);

export const patchFolder = (folderId: number, folder: Partial<Folder>) => patch(`/folder/${folderId}`, folder);;

export const createFolder = (folderName: string, parentId: number) => post<Folder>("/folder/", { name: folderName, parent_id: parentId });

export const deleteFolder = (folderId: number) => del<void>(`/folder/${folderId}`);

export const createCategorie = (folderName: string) => post<Folder>("/folder/top-bar", { name: folderName });