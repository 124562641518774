import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { get } from "../../api/api";
import { Folder } from "../type";

const RouteState = atom<Folder | undefined>({
  key: "route",
  default: undefined,
});

export function useRoutes() {
  const [routes, setRoutes] = useRecoilState(RouteState);

  const ensureRoutes = useCallback(
    async (force: boolean = false) => {
      if (!routes?.id || force) {
        console.log('fetching routes');
        const res = await get<Folder>("/folder/top-bar");
        console.log('ebbe');
        setRoutes(res);
        return res;
      }
      return routes;
    },
    [setRoutes, routes]
  );

  return { routes, setRoutes, ensureRoutes };
}

export const getFullRoutes = () => get<Folder>("/folder/top-bar/full");
