import React, { memo } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useRecoilState } from "recoil";
import { alertState, clearAlert } from "./atoms";

const Alert = memo(() => {
  const [alert, setAlert] = useRecoilState(alertState);

  const handleOk = () => {
    alert!.validateAction!();
    clearAlert(setAlert);
  };
  const handleExit = () => clearAlert(setAlert);

  return (
    <>
      {alert && (
        <Dialog open={!!alert} onExited={handleExit}>
          <DialogTitle>Information</DialogTitle>
          <DialogContent>
            <DialogContentText color="textPrimary">
              {alert!.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExit} color="primary" autoFocus>
              {alert!.validateAction ? "Annuler" : "Ok"}
            </Button>
            {alert.validateAction && (
              <Button onClick={handleOk} color="primary">
                Ok
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
});

export default Alert;
