import { Button, Tab, Tabs, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { Tab as STab, Tabs as STabs } from "@tarragon/swipeable-tabs";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { createFolder, createPage, deleteFolder, getFolder, patchFolder } from "../../../store/routes/action";
import { Folder as FolderType } from "../../../store/type";
import { useUser } from "../../../store/user/user";
import { useAlert } from "../../common/Alert/atoms";
import LoadingComponent from "../../common/LoadingComponent/LoadingComponent";
import { usePrompt } from "../../common/Prompt/atoms";
import { useSnackbar } from "../../common/Snackbar/atoms";
import SubFolder from "./subFolder";

type WrapperProps = {
  index: number;
  value: number;
  children: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  folderButton: {
    margin: "auto 36px",
    height: "36px",
  },
  pageTitle: {
    fontFamily: "Bree",
  },
}));

export function TabWrapper(props: WrapperProps) {
  const { index, value, children } = props;

  return <>{index === value && children}</>;
}

function Folder() {
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const history = useHistory();
  const { folderName } = useParams<{ folderName?: string }>();
  const [folder, setFolder] = useState<FolderType>();
  const prompt = usePrompt({ pageName: { label: "Nom de la page" } });
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = folder?.sub_folders.map((f, i) => {
    return (
      <STab key={f.position} label={`${f.position}`}>
        <SubFolder shown={selectedTab === f.position} user={user!} folder={f} />
      </STab>
    )
  }) || [];

  useEffect(() => {
    setLoading(true);
    getFolder(folderName!)
      .then((folder) => {
        setFolder(folder);
        setSelectedTab(0);
      })
      .catch(() => {
        snackbar("error", "Page introuvable");
        history.push("/admin");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [folderName, history, snackbar]);

  const subFolders = folder?.sub_folders;
  const doUpdateTab = useCallback((num: number) => {
    setSelectedTab(num);
    (async () => {
      document.title = subFolders![num].name || "";
    })();
  }, [subFolders]);

  const changeTab = useCallback((selected: { key: string | number, label: string }) => {
    doUpdateTab(+selected.key);
  }, [doUpdateTab]);

  const handleChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => doUpdateTab(newValue), [doUpdateTab]);

  const newFolder = async () => {
    const { pageName: folderName } = await prompt();
    const newFolder = await createFolder(folderName, folder!.id);
    setFolder((folder) => {
      folder?.sub_folders.push(newFolder);
      const f = { ...folder! };
      return f;
    });
  };



  const newPage = async () => {
    const { pageName } = await prompt();
    // TODO: finir de mettre à jour la partie UI
    const newPage = await createPage(folder!.sub_folders[selectedTab].id, pageName);
    setFolder((old) => {
      const d = { ...old! };
      d.sub_folders[selectedTab].pages.push(newPage);
      return d;
    });
  };

  const deleteFolder_ = useCallback(() => {
    // todo faire un truc commun
    alert("Voulez vous supprimer ce dossier ?", async () => {
      const folderId = folder!.sub_folders[selectedTab].id;
      await deleteFolder(folderId);
      setFolder((old) => {
        const d = { ...old! };
        d.sub_folders = d.sub_folders.filter((f) => f.id !== folderId);
        return d;
      });
    });
  }, [selectedTab, folder, alert]);

  return (
    <div style={{ padding: "1em" }}>
      <LoadingComponent loading={loading}>
        {user?.admin && (
          <div style={{ display: "flex" }}>
            <Typography variant="h1" className={classes.pageTitle}>
              {folder?.name}
            </Typography>

            <Button
              onClick={newFolder}
              variant="contained"
              color="default"
              className={classes.folderButton}
              startIcon={<Icon>add-box</Icon>}
            >
              Nouveau dossier
            </Button>
          </div>
        )}
        <Tabs value={selectedTab} onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary.main } }}>
          {folder?.sub_folders.map((f) => (
            <Tab key={f.name} label={f.name} className={classes.pageTitle} />
          ))}
        </Tabs>
        {(folder?.sub_folders.length || 0) > 0 && user?.admin && (
          <>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<Icon>add-box</Icon>}
              onClick={newPage}
            >
              Nouvelle Page
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={deleteFolder_}
            >
              Supprimer le dossier
            </Button>
          </>
        )}
        {/* <div style={{ display: "flex" }}> */}
        <STabs
          styleProps={{ showInkBar: false }}
          tabBarCSS="display: none;"
          value={`${selectedTab}`}
          onChange={changeTab}
        >
          {tabs}
        </STabs>
        {/* </div> */}
      </LoadingComponent>
    </div >
  );
}

export default Folder;
