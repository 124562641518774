import { makeStyles, Theme, ThemeProvider } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import React, { lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { useRoutes } from "../../../store/routes/hook";
import { makeTheme } from "../../../theme";
import OneProvider from "../../pages/Admin/ExternalResources/OneProvider";

// main pages
import Folder from "../../pages/Folder/Folder";
import Page from "../../pages/Page/Page";
import ViewEmbed from "../../pages/ViewEmbed/ViewEmbed";
import Alert from "../Alert/Alert";
import { useDarkmode } from "../DarkmodeToggle/Toggle";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Prompt from "../Prompt/Prompt";
import Snackbar from "../Snackbar/Snackbar";
import Layout from "./Layout";

const EditPage = lazy(() => import("../../pages/Admin/editPage/EditPage"));
const Admin = lazy(() => import("../../pages/Admin/Admin"));
const HowTo = lazy(() => import("../../pages/Admin/HowTo"));
const Login = lazy(() => import("../../pages/Login/Login"));
const NotFound = lazy(() => import('../../pages/404/404'));



const useStyles = makeStyles((theme: Theme) => ({
  mainDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  container: {
    backgroundColor: theme.palette.background.default,
  },
  baseLoading: {
    height: "100vh"
  },
}));

const loadingText = "Démarrage de l'application";

function Router() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { routes, ensureRoutes } = useRoutes();
  const { keycloak, initialized } = useKeycloak();
  const [themeSettings] = useDarkmode();
  const theme = makeTheme(themeSettings);


  useEffect(() => {
    if (initialized) {
      ensureRoutes();
    }

  }, [ensureRoutes, initialized]);


  const firstRoute = routes?.sub_folders[0];

  useEffect(() => {
    // console.log(routes, location.pathname, location.pathname === "/", location.pathname === "/" && routes?.id);
    if (location.pathname === "/" && routes?.id) {
      history.push(`/folder/${firstRoute?.name}`);
    }
  }, [location.pathname, routes?.id, history, firstRoute, initialized]);

  console.log(routes);

  if (!initialized) {
    return (
      <LoadingComponent
        text={loadingText}
        // className={classes.baseLoading}
        loading={!initialized} >

      </LoadingComponent>
    )
  }
  if (!keycloak.authenticated) {
    history.push('/login');
  }


  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <div className={classes.container}>
        <div className={classes.mainDiv}>
          <Alert />
          <Snackbar />
          <Prompt />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/404" exact component={NotFound} />
            {/* pas fou mais fait le taff pour l'insant */}
            {location.pathname !== "/login" &&
              <LoadingComponent
                text="Chargement des meilleures formations !"
                style={{ height: "100vh" }}
                loading={!Boolean(routes)}
              >
                <Route>
                  <Layout>
                    <Switch>
                      <Route path="/view">
                        <ViewEmbed />
                      </Route>
                      <Route path="/edit/:id">
                        <EditPage />
                      </Route>
                      <Route path="/page/:id">
                        <Page embeded={false} />
                      </Route>
                      <Route path="/folder/:folderName">
                        <Folder />
                      </Route>
                      <Route path="/admin/provider/:id" component={OneProvider} />
                      <Route path="/admin" component={Admin} />
                      <Route path="/howTo">
                        <HowTo />
                      </Route>
                      <Redirect to="/404" />
                    </Switch>
                  </Layout>
                </Route>
              </LoadingComponent>
            }
          </Switch>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Router;
