import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { hjid, hjsv } from '../../../App';


export default function Hotjar() {
    useEffect(() => {
        hotjar.initialize(hjid, hjsv);
    }, []);
    return (
        <>
        </>
    );
}