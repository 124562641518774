import React, { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Router from "./components/common/router/Router";
import { Helmet } from "react-helmet";
import { QueryParamProvider } from "use-query-params";
import { CircularProgress, CssBaseline, makeStyles } from "@material-ui/core";
import './index.css'
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { makePersistor } from "./utils/utils";
import jwtDecode from "jwt-decode";
import { useStoreUser } from "./store/user/user";

export const kiwiAddress = "https://kiwi.junior-entreprises.com";

export const gTag = "G-1CYM0BZQ0F";

export const hjid = 2474005;
export const hjsv = 6;

const useStyles = makeStyles(() => ({
  div: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  progress: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const { set: setKeycloak, get: getKeycloak } = makePersistor<{ refreshToken: string, token: string }>("KEYCLOAK_TOKENS");

function App() {
  const classes = useStyles();
  const tokens = getKeycloak();
  const storeUser = useStoreUser();
  return (
    <>
      {gTag &&
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gTag}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gTag}');
          `}
          </script>
        </Helmet>
      }
      <Suspense
        fallback={
          <div className={classes.div}>
            <CircularProgress className={classes.progress} />
          </div>
        }>
        <CssBaseline />
        <BrowserRouter>
          <ReactKeycloakProvider
            onTokens={(tokens) => {
              // setKeycloak({ token: tokens.token ?? '', refreshToken: tokens.refreshToken ?? '' });
              const { name, realm_access: { roles }, username } = jwtDecode<any>(tokens.token!);
              // @ts-ignore
              storeUser({ fullName: name, admin: roles.includes('admin'), username });
            }}
            // initOptions={{ token: tokens?.token, refreshToken: tokens?.refreshToken }}
            authClient={keycloak}
          >
            <QueryParamProvider ReactRouterRoute={Route}>
              <Router />
            </QueryParamProvider>
          </ReactKeycloakProvider>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
