import { createTheme, responsiveFontSizes } from "@material-ui/core";
import { ThemeSettings } from "./store/theme/atom";
// import BreeWoff2 from './fonts/bree-webfont.woff2';

// const raleway = {
//     fontFamily: 'Raleway',
//     fontStyle: 'normal',
//     fontDisplay: 'swap',
//     fontWeight: 400,
//     src: `
//       local('Raleway'),
//       local('Raleway-Regular'),
//       url(${RalewayWoff2}) format('woff2')
//     `,
//     unicodeRange:
//       'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
//   };

export const makeTheme = (settings: ThemeSettings) => {
  const theme = createTheme({
    palette: {
      type: settings.dark ? "dark" : "light",
      primary: {
        main: "rgba(183, 26, 81, 1)",
      },
    },
    typography: {
      //        fontFamily: 'DIN',
    },
    overrides: {
      MuiCard: {
        root: {
          overflow: "hidden",
          margin: "2rem 0px",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 40px -12px !important",
          borderRadius: "1rem !important",
        }
      }
    },
  });
  return responsiveFontSizes(theme);
};
