import { useCallback } from "react";
import Recoil, { atom, useSetRecoilState } from "recoil";

export type AlertState = {
  message: string;
  validateAction?: (() => void) | (() => Promise<void>);
} | null;

export const alertState = atom<AlertState>({
  key: "alerState",
  default: null,
});

export const clearAlert = (setter: Recoil.SetterOrUpdater<AlertState>) =>
  setter(null);

export const useAlert = () => {
  const setter = useSetRecoilState(alertState);
  return useCallback(
    (message: string, validateAction: () => void) => {
      setter({ message, validateAction });
    },
    [setter]
  );
};
