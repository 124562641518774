import { makeStyles, Theme, Card, CardMedia, CardActions, Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { type } from 'os';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Page } from '../../../utils/utils';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const useStyles = makeStyles((theme: Theme) => ({
  uploadCard: {
    height: "4em",
    margin: "1em",
    padding: "1em",
  },
  mainDiv: {
    width: "100vw",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'space-evenly',
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  padding: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  outCard: {
    margin: "2em",
    bordeRadius: "24px 24px 24px 24px",
    boxShadow: "5px -3px 17px -4px rgba(0,0,0,0.43)",
  },
  buttonCard: {
    width: "100%",
    height: "3em",
  },
  noPadding: {
    padding: "0",
  },
  mediaCard: {
    height: "21em",
    margin: "1em",
    width: "30%",
  },
  media: {
    height: "18em",
  },
}));

type Props<T> = {
  getter: (page: number) => Promise<Page<T>>;
  component: FunctionComponent<T>;
}

const PAGE_SIZE = 10;

export default function PagedItems<T extends { id: number }>(props: Props<T>) {
  const { getter, component: Component } = props;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNumber, setItemNumber] = useState(0);
  const [items, setItems] = useState<T[]>([]);


  const classes = useStyles();

  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
  };


  const loadItems = useCallback(() => {
    setLoading(true);
    getter(currentPage)
      .then((page) => {
        setItems(page.data);
        setItemNumber(page.count);
      })
      .finally(() => setLoading(false));
  }, [currentPage]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <Pagination
          count={Math.ceil(itemNumber / PAGE_SIZE)}
          page={currentPage}
          size="large"
          color="primary"
          onChange={handleChangePage}
          className={classes.pagination}
          showFirstButton
          showLastButton
        />
      </div>
      <LoadingComponent loading={loading} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className={classes.mainDiv}>
          {items.map(i => <Component key={i.id} {...i} />)}
        </div>
      </LoadingComponent>
    </>
  );
}
