import { fade, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const drawerWidthOpen = 220;

export const drawerWidthClose = 0;

export const appBarHeight = "4em";

export const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    textAlign: "center",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.contrastText, 0.5),
    },
    height: "2.6rem",
  },
  secondaryColor: {
    color: theme.palette.primary.contrastText,
  },
  primaryColor: {
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  customListItem: {
    color: theme.palette.primary.contrastText,
    height: "3rem",
  },
  div: {
    minHeight: `calc(100% - ${appBarHeight}px)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));
