import {
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import { getPage } from "../../../store/routes/action";
import { Page } from "../../../store/type";
import { useUser } from "../../../store/user/user";
import { useTitle } from "../../../utils/utils";
import LoadingComponent from "../../common/LoadingComponent/LoadingComponent";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "70em",
    margin: "1rem auto",
    maxWidth: '97%',
    display:'block'
  },
  div: {
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonCard: {
    width: "100%",
    height: "3em",
  },
}));

type Props = {
  id?: number;
  embeded: boolean;
};


function PageComponent(props: Props) {
  const { id: propsId } = props;
  const user = useUser();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const id_ = propsId || Number(id);
  const [data, setData] = useState<Page>();
  useEffect(() => {
    getPage(id_).then(setData);
  }, [id_]);
  useTitle(data?.name);

  const edit = () => history.push(`/edit/${id_}`);

  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <LoadingComponent loading={!data}>
            <div className={classes.div}>
              <Typography variant="h2" component="h1">
                {data && !data.hide_title && data.name}
              </Typography>
            </div>
            {/* this button is just there to enforce css injection */}
            <Button style={{ display: 'none' }} >
              Empty
            </Button>
            {user?.admin && (
              <Card>
                <div>
                  <Button
                    onClick={edit}
                    variant="contained"
                    color="default"
                    className={classes.buttonCard}
                    startIcon={<EditIcon />}
                  >
                    Modifier la page
                  </Button>
                </div>
              </Card>
            )}
            <br />
            <CardContent style={{ fontSize: "1.6em" }}>
              {data && (
                <>
                  {/* TODO transform strong node to use the color theme */}
                  <Markdown allowDangerousHtml={true} >
                    {data.value}
                  </Markdown>
                </>
              )}
            </CardContent>
          </LoadingComponent>
        </CardContent>
      </Card>
      <div style={{ height: '0.5rem' }}></div>
    </>
  );
}

export default PageComponent;
